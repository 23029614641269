import React from 'react';
import '../css/styles.scss';
import { Link } from 'gatsby';
import moment from 'moment';

const Layout = ({ children }) => {
    const startYear = 2015;
    const currentYear = moment().year();

    return (
        <React.Fragment>
            <div className="container">
                <nav className="navbar navbar-default" id="main-menu">
                    <div className="navbar-header">
                        <button
                            type="button"
                            className="navbar-toggle"
                            data-toggle="collapse"
                            data-target=".main-menu-collapse"
                        >
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <Link className="navbar-brand" to="/">
                            <img src="/assets/img/logo.svg" alt="Feudarium" />
                        </Link>
                        <Link className="navbar-brand" to="/">
                            Feudarium
                        </Link>
                    </div>
                </nav>

                {children}

                <div id="footer">
                    <ul>
                        <li>
                            &copy; {startYear} - {currentYear}{' '}
                            <a href="https://perunhq.org/">Bc. Milan Herda Perungrad</a>
                        </li>
                        <li>
                            <a href="https://blog.feudarium.com">Blog</a>
                        </li>
                        <li>
                            <a href="https://twitter.com/feudarium">
                                <span className="fab fa-twitter"></span> Twitter
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/feudarium/">
                                <span className="fab fa-facebook-square"></span> Facebook
                            </a>
                        </li>
                        <li>
                            <Link to="/contact">Kontakt</Link>
                        </li>
                        <li>
                            <Link to="/terms">Podmienky poskytovania služieb</Link>
                        </li>
                    </ul>
                    Použité ikonky: <a href="http://fortawesome.github.io/Font-Awesome/">Font Awesome</a>,{' '}
                    <a href="http://game-icons.net/">GameIcons</a>, <a href="http://glyphicons.com/">Glyphicons</a>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Layout;
